// src/pages/SignUp.js

import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, updateDoc, arrayRemove, getDoc } from 'firebase/firestore';

function SignUp() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [universityId, setUniversityId] = useState(''); // New state for University/College User ID
  const [invitationType, setInvitationType] = useState(''); // New state for invitation type
  const [inviteCode, setInviteCode] = useState(''); // New state for invitation code
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous error messages
    setErrorMessage('');

    // Check if passwords match
    if (password !== confirmPassword) {
      setErrorMessage("Passwords don't match");
      return;
    }

    // Validate password complexity
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
    if (!passwordRegex.test(password)) {
      setErrorMessage(
        'Password must be at least 6 characters long and include uppercase letters, lowercase letters, and digits.'
      );
      return;
    }

    // If the user is a student, validate the University/College User ID
    if (role === 'student') {
      if (!universityId) {
        setErrorMessage('University/College User ID is required for students.');
        return;
      }
      const universityIdRegex = /^\d+$/;
      if (!universityIdRegex.test(universityId)) {
        setErrorMessage('University/College User ID must contain only digits.');
        return;
      }
    }

    // If the user is a professor, validate the invitation code
    if (role === 'professor') {
      if (!invitationType) {
        setErrorMessage('Invitation type is required for professors.');
        return;
      }
    
      if (!inviteCode) {
        setErrorMessage('Invitation code is required for professors.');
        return;
      }
    

        // Get the document corresponding to the invitation type
        const invitationDocRef = doc(db, 'invitation_codes', invitationType);
        const invitationDoc = await getDoc(invitationDocRef);
    
        if (!invitationDoc.exists()) {
          setErrorMessage('Invalid invitation type.');
          return;
        }
    
        const codes = invitationDoc.data().codes || [];
    
        // Check if the invitation code exists in the codes array
        if (!codes.includes(inviteCode.trim())) {
          setErrorMessage('Invalid invitation code.');
          return;
        }
     
    }
    

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Prepare user data
      const userData = {
        firstName,
        lastName,
        role,
        email,
        createdAt: new Date(),
      };

      if (role === 'student') {
        userData.universityId = universityId;
      }

      if (role === 'professor') {
        userData.invitationType = invitationType;
        userData.invitationCode = inviteCode.trim();
      }

      // Store user details in Firestore
      await setDoc(doc(db, 'users', user.uid), userData);

      alert('Welcome to CoursEngage!');

      // Clear form fields
      setFirstName('');
      setLastName('');
      setRole('');
      setUniversityId('');
      setInvitationType('');
      setInviteCode('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setErrorMessage('');

      // Redirect to sign-in page
      window.location.href = '/sign-in';
    } catch (error) {
      console.error('Error creating user:', error);
      setErrorMessage(error.message.replace('firebase', 'CoursEngage'));
    }
  };

  return (
    <>
      <section className="sign-in-form section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto col-12">
              <h1 className="hero-title text-center mb-5">Sign Up</h1>
              <div className="row">
                <div className="col-lg-8 col-11 mx-auto">
                  <form id="signupForm" onSubmit={handleSubmit}>
                    <div className="form-floating my-4">
                      <input
                        type="text"
                        id="first_name"
                        className="form-control"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                      <label htmlFor="first_name">First Name</label>
                    </div>

                    <div className="form-floating my-4">
                      <input
                        type="text"
                        id="last_name"
                        className="form-control"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                      <label htmlFor="last_name">Last Name</label>
                    </div>

                    <div className="form-floating my-4">
                      <select
                        className="form-select"
                        id="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        required
                      >
                        <option value="" disabled hidden></option>
                        <option value="student">Student</option>
                        <option value="professor">Professor</option>
                      </select>
                      <label htmlFor="role">You are a...</label>
                    </div>

                    {/* Conditionally render the University/College User ID field for students */}
                    {role === 'student' && (
                      <div className="form-floating my-4">
                        <input
                          type="text"
                          id="university_id"
                          className="form-control"
                          placeholder="University/College User ID"
                          value={universityId}
                          onChange={(e) => setUniversityId(e.target.value)}
                          required
                        />
                        <label htmlFor="university_id">University/College User ID</label>
                        <p className="text-center text-muted">
                          * Must contain only digits
                        </p>
                      </div>
                    )}

                    {/* Conditionally render the Invitation Type and Invitation Code fields for professors */}
                    {role === 'professor' && (
                      <>
                        <div className="form-floating my-4">
                          <select
                            className="form-select"
                            id="invitation_type"
                            value={invitationType}
                            onChange={(e) => setInvitationType(e.target.value)}
                            required
                          >
                            <option value="" disabled hidden></option>
                            <option value="promo">Promo</option>
                            <option value="enterprise">Enterprise</option>
                            {/* Add more invitation types as needed */}
                          </select>
                          <label htmlFor="invitation_type">Invitation Type</label>
                        </div>

                        <div className="form-floating my-4">
                          <input
                            type="text"
                            id="invite_code"
                            className="form-control"
                            placeholder="Invitation Code"
                            value={inviteCode}
                            onChange={(e) => setInviteCode(e.target.value)}
                            required
                          />
                          <label htmlFor="invite_code">Invitation Code</label>
                          <p className="text-center text-muted">
                            Please enter your invitation code to proceed.
                          </p>
                        </div>
                      </>
                    )}

                    <div className="form-floating my-4">
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <label htmlFor="email">Email address</label>
                    </div>

                    <div className="form-floating my-4">
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <label htmlFor="password">Password</label>
                      <p className="text-center text-muted">
                        * Must include uppercase, lowercase, digits, and be at least 6 characters long
                      </p>
                    </div>

                    <div className="form-floating">
                      <input
                        type="password"
                        id="confirm_password"
                        className="form-control"
                        placeholder="Password Confirmation"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                      <label htmlFor="confirm_password">Password Confirmation</label>
                    </div>

                    <button
                      type="submit"
                      className="btn custom-btn form-control mt-4 mb-3"
                    >
                      Create account
                    </button>

                    {errorMessage && (
                      <div className="text-danger text-center mt-3">
                        {errorMessage}
                      </div>
                    )}

                    <p className="text-center">
                      Already have an account? Please{' '}
                      <a href="/sign-in">Sign In</a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SignUp;
