import React from 'react';

function WaitingForQuestion() {
  return (
    <section className="main-content section-padding text-center d-flex flex-column justify-content-center align-items-center vh-100">
      <div className="waiting-message">
        <h3 className="mb-3">
          <i className="fas fa-check-circle text-success me-2"></i>
          You are checked in!
        </h3>
        <p className="lead text-muted">
          Please stay ready! The question will be available as soon as your instructor posts it.
        </p>
      </div>
    </section>
  );
}

export default WaitingForQuestion;
