// src/components/professor/ClassDetails.js
import React, { useState, useEffect } from 'react';
import { db, database } from '../../../firebase';
import {
  doc,
  getDoc,
  updateDoc,
  arrayRemove,
  deleteField,
} from 'firebase/firestore';
import { getDatabase, ref, get , remove} from 'firebase/database';
import StudentList from './StudentList';
import SessionList from './SessionList';
import Stats from './Stats';
import ManageSession from './ManageSession';
import Preloader from '../../Preloader';

const ClassDetails = ({ selectedClass, closeClass }) => {
  const [students, setStudents] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [grades, setGrades] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [activeTab, setActiveTab] = useState('stats');

  const fetchClassDetails = async () => {
    setLoading(true);
    try {
      // Start fetching all data in parallel
      const enrollmentsPromise = getDoc(doc(db, 'enrollments', selectedClass.id));
      const gradesPromise = getDoc(doc(db, 'grades', selectedClass.id));
      const dbRealtime = getDatabase();
      const sessionsRef = ref(dbRealtime, `sessions/${selectedClass.id}`);
      const sessionsPromise = get(sessionsRef);

      const [enrollmentsDoc, gradesDoc, sessionsSnapshot] = await Promise.all([
        enrollmentsPromise,
        gradesPromise,
        sessionsPromise,
      ]);

      // Process students
      if (enrollmentsDoc.exists()) {
        const studentUIDs = enrollmentsDoc.data().students || [];
        const studentPromises = studentUIDs.map(async (uid) => {
          const userDoc = await getDoc(doc(db, 'users', uid));
          if (userDoc.exists()) {
            return { uid, ...userDoc.data() };
          }
          return null;
        });
        const studentsData = await Promise.all(studentPromises);
        setStudents(studentsData.filter((s) => s !== null));
      } else {
        setStudents([]);
      }

      // Process grades
      if (gradesDoc.exists()) {
        setGrades(gradesDoc.data());
      } else {
        setGrades({});
      }

      // Process sessions
      if (sessionsSnapshot.exists()) {
        const sessionsData = sessionsSnapshot.val();
        setSessions(
          Object.entries(sessionsData).map(([id, data]) => ({ id, ...data }))
        );
      } else {
        setSessions([]);
      }
    } catch (error) {
      console.error('Error fetching class details:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClassDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeStudent = async (studentUid) => {
    if (!window.confirm('Are you sure you want to remove this student?')) {
      return;
    }
  
    setLoading(true);
  
    try {
      // Remove student from enrollments
      await updateDoc(doc(db, 'enrollments', selectedClass.id), {
        students: arrayRemove(studentUid),
      });
  
      // Remove student's grades
      await updateDoc(doc(db, 'grades', selectedClass.id), {
        [`students.${studentUid}`]: deleteField(),
      });
  
      // Function to delete student's answers from Realtime Database
      const deleteStudentAnswers = async () => {
        const answersRef = ref(database, 'answers');
        const classesSnapshot = await get(answersRef);
  
        if (!classesSnapshot.exists()) {
          console.log('No classes found in answers.');
          return;
        }
  
        const classes = classesSnapshot.val();
  
        // Iterate over each class
        for (const classId in classes) {
          const sessions = classes[classId];
  
          // Iterate over each session in the class
          for (const sessionId in sessions) {
            const questions = sessions[sessionId];
  
            // Iterate over each question in the session
            for (const questionNumber in questions) {
              const studentAnswers = questions[questionNumber];
  
              // Check if the student's answer exists for this question
              if (studentAnswers && studentAnswers.hasOwnProperty(studentUid)) {
                const answerRef = ref(
                  database,
                  `answers/${classId}/${sessionId}/${questionNumber}/${studentUid}`
                );
  
                // Remove the student's answer
                await remove(answerRef);
                console.log(
                  `Removed answer for student ${studentUid} from Class ${classId}, Session ${sessionId}, Question ${questionNumber}`
                );
              }
            }
          }
        }
      };
  
      // Call the function to delete student's answers
      await deleteStudentAnswers();
  
      // Refresh students and grades
      await fetchClassDetails();
    } catch (error) {
      console.error('Error removing student:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <div className="class-details container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>{selectedClass.class_name}</h2>
        <button onClick={closeClass} className="btn btn-danger">
          &times;
        </button>
      </div>

      {/* Bootstrap Nav Tabs */}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 'stats' ? 'active' : ''}`}
            onClick={() => setActiveTab('stats')}
            role="tab"
            href="#!"
          >
            Stats
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 'students' ? 'active' : ''}`}
            onClick={() => setActiveTab('students')}
            role="tab"
            href="#!"
          >
            Students
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 'sessions' ? 'active' : ''}`}
            onClick={() => setActiveTab('sessions')}
            role="tab"
            href="#!"
          >
            Sessions
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 'manageSession' ? 'active' : ''}`}
            onClick={() => setActiveTab('manageSession')}
            role="tab"
            href="#!"
          >
            Manage Session
          </a>
        </li>
      </ul>

      <div className="tab-content mt-3">
        {activeTab === 'stats' && (
          <div className="tab-pane fade show active" role="tabpanel">
            <Stats
              students={students}
              sessions={sessions}
              grades={grades}
              classId={selectedClass.id}
            />
          </div>
        )}
        {activeTab === 'students' && (
          <div className="tab-pane fade show active" role="tabpanel">
            <StudentList
              students={students}
              grades={grades}
              removeStudent={removeStudent}
              selectedStudent={selectedStudent}
              setSelectedStudent={setSelectedStudent}
              sessions={sessions}
              classId={selectedClass.id}
            />
          </div>
        )}
        {activeTab === 'sessions' && (
          <div className="tab-pane fade show active" role="tabpanel">
            <SessionList
              sessions={sessions}
              classId={selectedClass.id}
              fetchClassDetails={fetchClassDetails}
            />
          </div>
        )}
         {activeTab === 'manageSession' && (
          <div className="tab-pane fade show active" role="tabpanel">
            <ManageSession classId={selectedClass.id} courseId={selectedClass.id}/>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassDetails;
