import React from 'react';

function AppDownloadSection() {
  const handleAPKDownload = () => {
    const userConfirmation = window.confirm(
      'Starting November 23, Google will test with 20 users for 14 days. As a small company, we recommend downloading the APK directly. By proceeding, you acknowledge the need to manually trust and install it. If you have concerns, feel free to visit our website. Continue with the download?'
    );    
    if (userConfirmation) {
      window.location.href = 'https://coursengage.netlify.app/app-release.apk';
    }
  };

  return (
    <section className="app-download-section text-center section-padding" style={{ backgroundColor: '#f8f9fa' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h2 className="display-4 font-weight-bold mb-4" style={{ color: '#343a40' }}>Get the App</h2>
            <p className="lead mb-5" style={{ color: '#6c757d' }}>
              Download our app now to unlock all the features. Available on the Play Store and App Store.
            </p>

            <div className="d-flex justify-content-center mb-4">
              <a onClick={handleAPKDownload} className="mx-3" style={{ cursor: 'pointer' }}>
                <img src="images/google-play-badge.png" alt="Download on Google Play" style={{ height: '60px' }} />
              </a>
              <a href="https://apps.apple.com/app/coursengage/id6736921958" className="mx-3" style={{ cursor: 'pointer' }}>
                <img src="images/app-store-badge.png" alt="Download on the App Store" style={{ height: '60px' }} />
              </a>
            </div>

            <div>
              <a href="/sign-in" className="btn custom-btn btn-lg">
                Sign in Directly
              </a>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <p className="display-6 mb-4" style={{ color: '#343a40' }}>Student Web Dashboard</p>
          <div className="col-lg-6">
            <img src="images/product/studentAnswerWeb.JPG" alt="App Preview" className="img-fluid rounded"
                 style={{ boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }} />
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <p className="display-6 mb-4" style={{ color: '#343a40' }}>Student Mobile App Dashboard</p>
          <div className="col-lg-6">
            <div className="d-flex justify-content-between">
              <img src="images/product/studentAnswerMobile.JPG" alt="App Preview 1" className="img-fluid rounded me-2"
                   style={{ width: '48%', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }} />
              <img src="images/product/Capture2.JPG" alt="App Preview 2" className="img-fluid rounded ms-2"
                   style={{ width: '48%', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }} />
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <p className="display-6 mb-4" style={{ color: '#343a40' }}>Professor Desktop Software</p>
          <div className="col-lg-6">
            <img src="images/product/professorDesktopQuestion.JPG" alt="App Preview" className="img-fluid rounded"
                 style={{ boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }} />
          </div>
          <div className="col-lg-6">
            <img src="images/product/professorDesktopAnswer.JPG" alt="App Preview" className="img-fluid rounded"
                 style={{ boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AppDownloadSection;
