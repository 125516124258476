// import React, { useState, useEffect } from 'react';
// import { auth, db, functions } from '../../firebase';
// import { collection, query, where, getDocs } from 'firebase/firestore';
// import Preloader from '../../components/Preloader';
// import { httpsCallable } from 'firebase/functions';
// import { onAuthStateChanged } from 'firebase/auth';

// function IntegrationSection() {
//   const [activeTab, setActiveTab] = useState('grade');
//   const [selectedService, setSelectedService] = useState('');
//   const [assignmentUrl, setAssignmentUrl] = useState('');
//   const [apiToken, setApiToken] = useState('');
//   const [errors, setErrors] = useState({});
//   const [reportData, setReportData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [selectedClassId, setSelectedClassId] = useState('');
//   const [classes, setClasses] = useState([]);
//   const [user, setUser] = useState(null); // Add user state

//   useEffect(() => {
//     // Monitor authentication state
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       if (currentUser) {
//         console.log('User is authenticated:', currentUser);
//         setUser(currentUser);
//       } else {
//         console.log('No user is authenticated');
//         setUser(null);
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   useEffect(() => {
//     // Fetch professor's classes when user is authenticated
//     const fetchClasses = async () => {
//       if (!user) return;

//       setLoading(true);
//       try {
//         const q = query(
//           collection(db, 'classes'),
//           where('professor_uid', '==', user.uid)
//         );
//         const querySnapshot = await getDocs(q);
//         const classesData = querySnapshot.docs.map((docSnap) => ({
//           id: docSnap.id,
//           ...docSnap.data(),
//         }));
//         setClasses(classesData);
//       } catch (error) {
//         console.error('Error fetching classes:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchClasses();
//   }, [user]);

//   const handleServiceChange = (e) => {
//     setSelectedService(e.target.value);
//     setAssignmentUrl('');
//     setApiToken('');
//     setErrors({});
//   };

//   const validateAssignmentUrl = (url) => {
//     const pattern = /^https?:\/\/[^/]+\/courses\/\d+\/assignments\/\d+$/;
//     return pattern.test(url);
//   };

//   const handleClassChange = async (e) => {
//     const classId = e.target.value;
//     setSelectedClassId(classId);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let currentErrors = {};

//     if (!selectedClassId) {
//       currentErrors.selectedClassId = 'Please select a class.';
//     }

//     if (selectedService === 'canvas') {
//       if (!assignmentUrl) {
//         currentErrors.assignmentUrl = 'Assignment URL is required.';
//       } else if (!validateAssignmentUrl(assignmentUrl)) {
//         currentErrors.assignmentUrl = 'Assignment URL format is invalid.';
//       }
//       if (!apiToken) {
//         currentErrors.apiToken = 'API Token is required.';
//       }
//     }

//     if (Object.keys(currentErrors).length > 0) {
//       setErrors(currentErrors);
//       return;
//     }

//     if (!user) {
//       alert('You must be signed in to perform this action.');
//       return;
//     }

//     setLoading(true);
//     setErrors({});
//     try {
//       // Call the Cloud Function

//       if (user) {
//       const pushGradesToCanvas = httpsCallable(functions, 'pushGradesToCanvas');
//       const result = await pushGradesToCanvas({
//         assignmentUrl,
//         selectedClassId,
//         apiToken, // Pass the API token to the Cloud Function
//       });
   

//       const { reportData } = result.data;

//       // Update state with report data
//       setReportData(reportData);

//       alert('Grade push completed. See the report below.');
//     }
//     } catch (error) {
//       console.error('Error pushing grades:', error);
//       const errorMessage =
//         error.message ||
//         (error.code === 'functions/unauthenticated'
//           ? 'You must be signed in to perform this action.'
//           : 'Failed to push grades.');
//       alert(errorMessage);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const generateCsv = (reportData) => {
//     const csvRows = [];
//     csvRows.push(['Student', 'Grade', 'Status', 'Message']);

//     for (const row of reportData) {
//       csvRows.push([row.student, row.grade, row.status, `"${row.message}"`]);
//     }

//     const csvContent = csvRows.map((e) => e.join(',')).join('\n');
//     return csvContent;
//   };

//   const downloadCsv = () => {
//     const csvContent = generateCsv(reportData);
//     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//     const url = URL.createObjectURL(blob);

//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', 'grade_push_report.csv');
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   return (
//     <section
//       id="integration-section"
//       className="justify-content-center align-items-center text-center min-vh-100 mb-4 content-section"
//     >
//       {loading && <Preloader />}

//       {/* Bootstrap Nav Tabs */}
//       <ul className="nav nav-tabs" role="tablist">
//         <li className="nav-item">
//           <a
//             className={`nav-link ${activeTab === 'grade' ? 'active' : ''}`}
//             onClick={() => setActiveTab('grade')}
//             role="tab"
//             href="#!"
//           >
//             Grade
//           </a>
//         </li>
//       </ul>

//       <div className="tab-content mt-3">
//         {activeTab === 'grade' && (
//           <div className="tab-pane fade show active" role="tabpanel">
//             <p>
//               Select a service to integrate with your grading system.
//               Currently, only Canvas is supported.
//             </p>

//             {/* Select Class */}
//             <div className="row mb-3 align-items-center">
//               <label
//                 htmlFor="classSelect"
//                 className="col-sm-3 col-form-label text-end"
//               >
//                 Select Class:
//               </label>
//               <div className="col-sm-6">
//                 <select
//                   id="classSelect"
//                   value={selectedClassId}
//                   onChange={handleClassChange}
//                   className="form-select"
//                   required
//                 >
//                   <option value="">Select a class</option>
//                   {classes.map((cls) => (
//                     <option key={cls.id} value={cls.id}>
//                       {cls.class_name}
//                     </option>
//                   ))}
//                 </select>
//                 {errors.selectedClassId && (
//                   <div className="text-danger text-start">
//                     {errors.selectedClassId}
//                   </div>
//                 )}
//               </div>
//             </div>

//             <form onSubmit={handleSubmit} className="integration-form">
//               <div className="row mb-3 align-items-center">
//                 <label
//                   htmlFor="serviceSelect"
//                   className="col-sm-3 col-form-label text-end"
//                 >
//                   Select Service:
//                 </label>
//                 <div className="col-sm-6">
//                   <select
//                     id="serviceSelect"
//                     value={selectedService}
//                     onChange={handleServiceChange}
//                     className="form-select"
//                     required
//                   >
//                     <option value="">Select a service</option>
//                     <option value="canvas">Canvas</option>
//                   </select>
//                 </div>
//               </div>

//               {selectedService === 'canvas' && (
//                 <>
//                   <div className="row mb-3 align-items-center">
//                     <label
//                       htmlFor="assignmentUrl"
//                       className="col-sm-3 col-form-label text-end"
//                     >
//                       Assignment URL:
//                     </label>
//                     <div className="col-sm-6">
//                       <input
//                         type="text"
//                         id="assignmentUrl"
//                         name="assignmentUrl"
//                         value={assignmentUrl}
//                         onChange={(e) => setAssignmentUrl(e.target.value)}
//                         className="form-control"
//                         required
//                       />
//                       {errors.assignmentUrl && (
//                         <div className="text-danger text-start">
//                           {errors.assignmentUrl}
//                         </div>
//                       )}
//                       <small className="form-text text-muted text-start">
//                         Example format:
//                         https://yourdomain.edu/courses/12345/assignments/67890
//                       </small>
//                     </div>
//                   </div>

//                   <div className="row mb-3 align-items-center">
//                     <label
//                       htmlFor="apiToken"
//                       className="col-sm-3 col-form-label text-end"
//                     >
//                       API Token:
//                     </label>
//                     <div className="col-sm-6">
//                       <input
//                         type="password"
//                         id="apiToken"
//                         name="apiToken"
//                         value={apiToken}
//                         onChange={(e) => setApiToken(e.target.value)}
//                         className="form-control"
//                         required
//                       />
//                       {errors.apiToken && (
//                         <div className="text-danger text-start">
//                           {errors.apiToken}
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 </>
//               )}

//               {selectedService && (
//                 <div className="row">
//                   <div className="col-sm-3"></div>
//                   <div className="col-sm-6">
//                     <button type="submit" className="btn custom-btn">
//                       Push Grade and Generate Report
//                     </button>
//                   </div>
//                 </div>
//               )}
//             </form>

//             {/* Show report preview and download option */}
//             {reportData.length > 0 && (
//               <div className="mt-5">
//                 <h5>Grade Push Report</h5>
//                 <table className="table table-bordered">
//                   <thead>
//                     <tr>
//                       <th>Student</th>
//                       <th>Grade</th>
//                       <th>Status</th>
//                       <th>Message</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {reportData.map((row, index) => (
//                       <tr key={index}>
//                         <td>{row.student}</td>
//                         <td>{row.grade}</td>
//                         <td>{row.status}</td>
//                         <td>{row.message}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//                 <button onClick={downloadCsv} className="btn custom-btn">
//                   Download CSV Report
//                 </button>
//               </div>
//             )}
//           </div>
//         )}
//       </div>
//     </section>
//   );
// }

// export default IntegrationSection;

// src/components/professor/IntegrationSection.js

import React, { useState, useEffect } from 'react';
// ... other necessary imports

const FEATURE_ENABLED = false; // Toggle this flag to enable/disable the feature

function IntegrationSection() {
    return (
        <section
              id="integration-section"
              className="justify-content-center align-items-center text-center min-vh-100 mb-4 content-section"
            >
                <div className="container">
          <div>
            <h4 className="alert-heading">Feature Under Development</h4>
            <p>
              This feature is currently in development. Please check back later for updates.
            </p>
            <hr />
            <p className="mb-0">We appreciate your patience!</p>
          </div>
        </div>
                </section>
    );

  // ... original IntegrationSection code
}

export default IntegrationSection;
