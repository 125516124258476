import { Link, useLocation } from 'react-router-dom';

function Navbar({ user, handleSignOut }) {
  const location = useLocation();

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/student">
          <strong>
            <span>Cours</span>Engage
          </strong>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/student' ? 'active' : ''}`}
                to="/student"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/student-results' ? 'active' : ''}`}
                to="/student-results"
              >
                Results
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/student-settings' ? 'active' : ''}`}
                to="/student-settings"
              >
                Settings
              </Link>
            </li>
            {user && (
              <>
                <li className="nav-item">
                  <span className="nav-link">{user.email}</span>
                </li>
                <li className="nav-item">
                  <button className="nav-link btn btn-link" onClick={handleSignOut}>
                    Sign Out
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
