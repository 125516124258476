// src/components/professor/SessionList.js
import React, { useState } from 'react';
import { db } from '../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import {
    getDatabase,
    ref as refRealtime,
    get as getRealtime,
    update as updateRealtime,
} from 'firebase/database';
import {
    getStorage,
    ref as storageRef,
    uploadBytes,
    getDownloadURL,
    deleteObject,
} from 'firebase/storage';
import Preloader from '../../Preloader';

const SessionList = ({ sessions, classId, fetchClassDetails }) => {
    const [sessionSearchTerm, setSessionSearchTerm] = useState('');
    const [expandedSessions, setExpandedSessions] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState({});

    const handleSessionSearch = (e) => {
        setSessionSearchTerm(e.target.value);
    };

    const toggleSessionExpand = (sessionId) => {
        setExpandedSessions({
            ...expandedSessions,
            [sessionId]: !expandedSessions[sessionId],
        });
    };

    // Update the filteredSessions to handle date correctly
    const filteredSessions = sessions.filter((session) => {
        const sessionDate = new Date(session.start_date_time);
        return sessionDate
            .toLocaleDateString()
            .includes(sessionSearchTerm);
    });

    const formatAnswer = (answer) =>
        answer
            .toString()
            .toUpperCase()
            .split(',')
            .map((a) => a.trim())
            .filter((a) => a !== '');

    const updateCorrectAnswer = async (
        sessionId,
        questionNumber,
        newAnswer
    ) => {
        setLoading(true);
        try {
            const formattedAnswer = formatAnswer(newAnswer);
            const dbRealtime = getDatabase();
            const sessionQuestionsRef = refRealtime(
                dbRealtime,
                `sessions/${classId}/${sessionId}/questions/${questionNumber}`
            );

            // Update the correct_answer in Realtime Database
            await updateRealtime(sessionQuestionsRef, { correct_answer: formattedAnswer });

            // Regrade students based on the new correct answer
            await regradeStudents(sessionId, questionNumber, formattedAnswer);

            alert('Correct answer updated and students re-graded.');

            await fetchClassDetails();
        } catch (error) {
            console.error('Error updating correct answer:', error);
            alert('Failed to update correct answer. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const regradeStudents = async (sessionId, questionNumber, newAnswer) => {
        try {
            // Fetch students' answers from Realtime Database
            const dbRealtime = getDatabase();
            const answersRef = refRealtime(
                dbRealtime,
                `answers/${classId}/${sessionId}/${questionNumber}`
            );
            const snapshot = await getRealtime(answersRef);
            if (snapshot.exists()) {
                const answersData = snapshot.val();
                const gradesDocRef = doc(db, 'grades', classId);

                // Find the corresponding session and question to get question_type
                const session = sessions.find(s => s.id === sessionId);
                if (!session) {
                    console.error(`Session with ID ${sessionId} not found.`);
                    alert(`Session with ID ${sessionId} not found.`);
                    return;
                }

                const question = session.questions[questionNumber];
                if (!question) {
                    console.error(`Question number ${questionNumber} not found in session ${sessionId}.`);
                    alert(`Question number ${questionNumber} not found in session ${sessionId}.`);
                    return;
                }

                const questionType = question.question_type;
                const formattedCorrectAnswer = newAnswer; // Already formatted

                // Prepare updates
                const gradeUpdates = {};

                for (const studentUid in answersData) {
                    const studentAnswerRaw = answersData[studentUid].answer;
                    const studentAnswer = formatAnswer(studentAnswerRaw);

                    let pointsEarned = 0;

                    // Grading Logic Based on Question Type
                    if (questionType === 'multiple_choice') {
                        if (studentAnswer.length > 0) {
                            const isCorrect = formattedCorrectAnswer.includes(studentAnswer[0]);
                            pointsEarned = isCorrect ? 1 : 0;
                        }
                    } else if (questionType === 'multiple_multiple_choice') {
                        const totalCorrectAnswers = formattedCorrectAnswer.length;
                        if (totalCorrectAnswers > 0) {
                            const pointPerCorrectAnswer = 1 / totalCorrectAnswers;
                            const correctSelections = studentAnswer.filter(answer =>
                                formattedCorrectAnswer.includes(answer)
                            ).length;
                            const incorrectSelections = studentAnswer.filter(answer =>
                                !formattedCorrectAnswer.includes(answer)
                            ).length;

                            pointsEarned =
                                (correctSelections * pointPerCorrectAnswer) -
                                (incorrectSelections * pointPerCorrectAnswer);
                            pointsEarned = Math.max(0, Math.round(pointsEarned * 100) / 100);
                        }
                    } else if (questionType === 'yes_no') {
                        if (studentAnswer.length > 0) {
                            const isCorrect = formattedCorrectAnswer.includes(studentAnswer[0]);
                            pointsEarned = isCorrect ? 1 : 0;
                        }
                    } else if (questionType === 'short_input') {
                        // For short input, implement case-insensitive exact match
                        if (studentAnswer.length > 0) {
                            const isCorrect = formattedCorrectAnswer.some(
                                correct => correct.toUpperCase() === studentAnswer[0]
                            );
                            pointsEarned = isCorrect ? 1 : 0;
                        }
                    }

                    // Update grades in Firestore with the correct field path
                    gradeUpdates[`students.${studentUid}.${sessionId}.points.${questionNumber}`] = pointsEarned;
                }

                if (Object.keys(gradeUpdates).length > 0) {
                    await updateDoc(gradesDocRef, gradeUpdates);
                    console.log('Grades updated successfully.');
                } else {
                    console.log('No grades to update.');
                }
            } else {
                console.log('No answers found for regrading.');
                alert('No answers found for regrading.');
            }
        } catch (error) {
            console.error('Error regrading students:', error);
            alert('Failed to regrade students. Please check the console for more details.');
        }
    };

    const handleImageUpload = async (sessionId, questionNumber, file) => {
        setLoading(true);
        try {
            const storage = getStorage();
            const storageReference = storageRef(
                storage,
                `questions/${classId}/${sessionId}/${questionNumber}/question_image.jpg`
            );
            await uploadBytes(storageReference, file);

            const downloadURL = await getDownloadURL(storageReference);

            const dbRealtime = getDatabase();
            const questionRef = refRealtime(
                dbRealtime,
                `sessions/${classId}/${sessionId}/questions/${questionNumber}`
            );
            await updateRealtime(questionRef, { question_photo: downloadURL });

            alert('Image uploaded successfully.');

            await fetchClassDetails();
            setSelectedFiles((prevFiles) => {
                const key = `${sessionId}-${questionNumber}`;
                const newFiles = { ...prevFiles };
                delete newFiles[key];
                return newFiles;
            });
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Error uploading image.');
        } finally {
            setLoading(false);
        }
    };

    const handleImageRemove = async (sessionId, questionNumber) => {
        setLoading(true);
        try {
            const storage = getStorage();
            const storageReference = storageRef(
                storage,
                `questions/${classId}/${sessionId}/${questionNumber}/question_image.jpg`
            );
            await deleteObject(storageReference);

            const dbRealtime = getDatabase();
            const questionRef = refRealtime(
                dbRealtime,
                `sessions/${classId}/${sessionId}/questions/${questionNumber}`
            );
            await updateRealtime(questionRef, { question_photo: "InClass" });

            alert('Image removed successfully.');

            await fetchClassDetails();
        } catch (error) {
            console.error('Error removing image:', error);
            alert('Error removing image.');
        } finally {
            setLoading(false);
        }
    };

    const questionTypeDisplay = {
        'multiple_choice': 'Multiple Choice',
        'multiple_multiple_choice': 'Multiple Answers',
        'yes_no': 'Yes/No',
        'short_input': 'Input',
    };

    if (loading) {
        return <Preloader />;
    }

    return (
        <>
            <h3>Sessions</h3>
            <input
                type="text"
                placeholder="Search Sessions..."
                value={sessionSearchTerm}
                onChange={handleSessionSearch}
                className="form-control-sm me-2 mb-3"
            />

            <div className="sessions-list">
                {filteredSessions.map((session) => {
                    const maxQuestionNumber = session.questions
                        ? Math.max(...Object.keys(session.questions).map(Number))
                        : 0;
                    return (
                        <div key={session.id} className="session-item mb-3">
                            <div
                                className="session-header d-flex justify-content-between align-items-center bg-light p-3 rounded"
                                onClick={() => toggleSessionExpand(session.id)}
                                style={{ cursor: 'pointer' }}
                            >
                                <h4 className="mb-0">
                                    Session on {new Date(session.start_date_time).toLocaleString()}
                                </h4>
                                <span>{expandedSessions[session.id] ? '▴' : '▾'}</span>
                            </div>

                            {expandedSessions[session.id] && (
                                <div className="session-details p-3">
                                    <p className="mb-4">
                                        <strong>Total Questions:</strong> {maxQuestionNumber}
                                    </p>
                                    {session.questions &&
                                        Object.entries(session.questions).map(([questionNumber, question]) => (
                                            <div key={questionNumber} className="question-item mb-4 p-3 border rounded">
                                                <h5 className="mb-3">Question {questionNumber}</h5>

                                                {question.question_photo ? (
                                                    question.question_photo === "InClass" ? (
                                                        <div className="alert alert-info" role="alert">
                                                            This question was presented in the class.
                                                        </div>
                                                    ) : (
                                                        <div className="mb-3">
                                                            <img
                                                                src={question.question_photo}
                                                                alt={`Question ${questionNumber}`}
                                                                className="img-fluid mb-3"
                                                            />
                                                            <button
                                                                onClick={() => handleImageRemove(session.id, questionNumber)}
                                                                className="btn custom-btn me-2"
                                                            >
                                                                Remove Image
                                                            </button>
                                                        </div>
                                                    )
                                                ) : null}

                                                {(!question.question_photo || question.question_photo === "InClass") && (
                                                    <div className="mb-3">
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(e) => {
                                                                const file = e.target.files[0];
                                                                const key = `${session.id}-${questionNumber}`;
                                                                setSelectedFiles({
                                                                    ...selectedFiles,
                                                                    [key]: file,
                                                                });
                                                            }}
                                                            className="form-control mb-2"
                                                        />
                                                        <button
                                                            onClick={() => {
                                                                const key = `${session.id}-${questionNumber}`;
                                                                const file = selectedFiles[key];
                                                                if (file) {
                                                                    handleImageUpload(session.id, questionNumber, file);
                                                                } else {
                                                                    alert('Please select a file first.');
                                                                }
                                                            }}
                                                            className="btn custom-btn"
                                                        >
                                                            Upload Image
                                                        </button>
                                                    </div>
                                                )}

                                                <p className="mb-2">
                                                    <strong>Type:</strong> {questionTypeDisplay[question.question_type] || question.question_type}
                                                </p>

                                                {question.possible_answers && (
                                                    <div className="mb-2">
                                                        <strong>Possible Answers:</strong>
                                                        <ul className="list-group list-group-flush mb-3">
                                                            {question.possible_answers.map((answer, index) => (
                                                                <li key={index} className="list-group-item">
                                                                    {answer}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}

                                                <p className="mb-3">
                                                    <strong>Correct Answer:</strong> {formatAnswer(question.correct_answer).join(', ') || 'Not set'}
                                                </p>

                                                <button
                                                    onClick={() => {
                                                        const existingAnswer = Array.isArray(question.correct_answer)
                                                            ? question.correct_answer.join(', ')
                                                            : question.correct_answer;
                                                        const newAnswer = prompt(
                                                            'Enter new correct answer:',
                                                            existingAnswer || ''
                                                        );
                                                        if (newAnswer !== null) {
                                                            updateCorrectAnswer(session.id, questionNumber, newAnswer);
                                                        }
                                                    }}
                                                    className="btn btn-secondary"
                                                >
                                                    Modify Correct Answer
                                                </button>
                                            </div>
                                        ))}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );

};

export default SessionList;
