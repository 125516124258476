import React from 'react';

function AddClassModal({
  showModal,
  handleModalClose,
  invitationCode,
  setInvitationCode,
  handleInvitationSubmit,
}) {
  if (!showModal) {
    return null;
  }

  return (
    <div id="class-form-modal" className="modal" style={{ display: 'block' }}>
      <div className="modal-content">
        <span
          className="close-btn"
          style={{ cursor: 'pointer' }}
          onClick={handleModalClose}
        >
          &times;
        </span>
        <h4>Enter Invitation Code</h4>
        <input
          type="text"
          id="invitation-code"
          className="form-control"
          placeholder="Invitation Code"
          required
          value={invitationCode}
          onChange={(e) => setInvitationCode(e.target.value)}
        />
        <button
          id="submit-invitation"
          className="btn custom-btn mt-3"
          onClick={handleInvitationSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default AddClassModal;
