import React, { useState } from 'react';
import { auth, db } from '../firebase';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';
import Preloader from '../components/Preloader';
import { useAuth } from '../contexts/AuthContext';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingState, setLoadingState] = useState(false); // Separate loading state for form submission
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading } = useAuth();

  const from = location.state?.from?.pathname || '/';

  // If user is already authenticated, redirect based on role
  React.useEffect(() => {
    if (!loading && user.isAuthenticated) {
      if (user.role === 'professor') {
        navigate('/professor', { replace: true });
      } else if (user.role === 'student') {
        navigate('/student', { replace: true });
      } else if (user.role === 'admin') {
        navigate('/admin-support', { replace: true });
      } else {
        navigate('/default', { replace: true });
      }
    }
  }, [user, loading, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingState(true);
    setErrorMessage('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const currentUser = userCredential.user;
      const userDocRef = doc(db, 'users', currentUser.uid);
      const docSnapshot = await getDoc(userDocRef);
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        // Note: AuthContext will automatically update via onAuthStateChanged
        // Redirect handled by useEffect
      } else {
        alert('User data not found.');
        await signOut(auth);
      }
    } catch (error) {
      console.error('Error during sign-in:', error);
      setErrorMessage(error.message.replace('Firebase', 'CoursEngage'));
    } finally {
      setLoadingState(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const emailAddress = prompt(
      'Please enter your email address to reset your password:'
    );
    if (emailAddress) {
      try {
        await sendPasswordResetEmail(auth, emailAddress);
        alert('Password reset email sent! Please check your inbox.');
      } catch (error) {
        console.error('Error sending password reset email:', error);
        setErrorMessage(error.message.replace('Firebase', 'CoursEngage'));
      }
    }
  };

  if (loading) {
    return <Preloader />; // Show loading while checking auth state
  }

  return (
    <section className="sign-in-form section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto col-12">
            <h1 className="hero-title text-center mb-5">Sign In</h1>
            <div className="row">
              <div className="col-lg-8 col-11 mx-auto">
                <form id="sign-in-form" onSubmit={handleSubmit}>
                  <div className="form-floating mb-4 p-0">
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="Email address"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="email">Email address</label>
                  </div>
                  <div className="form-floating p-0">
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label htmlFor="password">Password</label>
                  </div>
                  <button
                    type="submit"
                    className="btn custom-btn form-control mt-4 mb-3"
                    disabled={loadingState}
                  >
                    {loadingState ? 'Signing in...' : 'Sign in'}
                  </button>
                  <p className="text-center">
                    Don’t have an account?{' '}
                    <a href="/sign-up">Create One</a>
                  </p>
                  <p className="text-center">
                    Forgot Password?{' '}
                    <a href="#" onClick={handleForgotPassword}>
                      Forgot Password
                    </a>
                  </p>
                </form>
                {errorMessage && (
                  <div
                    id="error-message"
                    className="text-danger text-center mt-3"
                  >
                    {errorMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
