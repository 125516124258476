import React, { useEffect } from 'react';

const About = () => {
  useEffect(() => {
    // Add 'fade-in' class to trigger Bootstrap's fade animation on component mount
    const headerImage = document.getElementById('header-image');
    if (headerImage) {
      headerImage.classList.add('fade', 'show');
    }
  }, []);

  return (
    <>
      {/* Header Section */}
      <header className="site-header bg-light py-5 pt-5">
        <div className="container">
          <div className="row align-items-center">
            {/* Header Information */}
            <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
              <h1 className="display-4">
                <span className="text-primary">Cours</span>
                <span className="text-dark">Engage</span>
              </h1>
              <h3 className="text-muted">by Debugger Developers</h3>
            </div>
            {/* Header Image */}
            <div className="col-lg-6 col-md-12">
              <img 
                id="header-image"
                src="images/people/dd.png" 
                className="img-fluid rounded shadow fade" 
                alt="Header" 
                style={{ width: '80%', transition: 'opacity 2s', marginTop:50}}
              />
            </div>
          </div>
        </div>
      </header>

      {/* Team Section */}
      <section className="py-5">
        <div className="container">
          {/* Section Title */}
          <div className="text-center mb-5">
            <h2>
              Meet our <span className="text-primary">Team</span>
            </h2>
          </div>

          {/* Team Members */}
          <div className="row">
            {/* Team Member Template */}
            {[
              {
                name: 'Karnav',
                role: 'Founder/CEO',
                education: 'M.S. in Computer Science (Student)',
                university: 'University of Nebraska-Lincoln',
                img: 'images/people/karnav.png',
              },
              {
                name: 'Logan',
                role: 'Advisor',
                education: 'Bachelors in Software Engineering',
                university: 'University of Nebraska-Lincoln',
                img: 'images/people/logan.jpeg',
              },
              {
                name: 'Vrushik',
                role: 'Advisor',
                education: 'B. Tech Computer Engineering',
                university: 'Charotar University of Science and Technology (CHARUSAT)',
                img: 'images/people/vrushik.jfif',
              },
              // {
              //   name: 'Pranav',
              //   role: 'Lead Mobile App Developer',
              //   education: 'M.S. in Computer Science (Student)',
              //   university: 'University of Nebraska-Lincoln',
              //   img: 'images/people/pranav.jfif',
              // },
              // {
              //   name: 'Bharath',
              //   role: 'Lead Software Engineer',
              //   education: 'Bachelor of Computer Science',
              //   university: 'University of Nebraska-Lincoln',
              //   img: 'images/people/bharath.jfif',
              // },
            ].map((member, index) => (
              <div className="col-md-6 col-lg-3 mb-4" key={index}>
                <div className="card h-100 text-center shadow-sm">
                  <img 
                    src={member.img} 
                    className="card-img-top rounded-circle mx-auto mt-4" 
                    style={{ width: '150px', height: '150px', objectFit: 'cover' }} 
                    alt={member.name} 
                  />
                  <div className="card-body">
                    <h5 className="card-title">{member.name}</h5>
                    <p className="card-text text-muted">{member.role}</p>
                    <p className="card-text">{member.education}</p>
                    <p className="card-text"><small className="text-muted">{member.university}</small></p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
