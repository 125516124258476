// src/components/professor/ExportSection.js

import React, { useState, useEffect } from 'react';
import Preloader from '../../components/Preloader';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { getDatabase, ref, get } from 'firebase/database';
import { db } from '../../firebase';
import { getAuth } from 'firebase/auth';

const ExportSection = () => {
    const [loading, setLoading] = useState(false);
    const [classes, setClasses] = useState([]);
    const [selectedClassId, setSelectedClassId] = useState('');

    useEffect(() => {
        const fetchClasses = async () => {
            setLoading(true);
            try {
                const authInstance = getAuth();
                const user = authInstance.currentUser;
                if (!user) {
                    alert('No user is logged in.');
                    setLoading(false);
                    return;
                }

                // Define two separate queries:
                // 1. Classes where the user is the owner
                const ownedClassesQuery = query(
                    collection(db, 'classes'),
                    where('professor_uid', '==', user.uid)
                );

                // 2. Classes where the user has been granted access
                const accessibleClassesQuery = query(
                    collection(db, 'classes'),
                    where('access_uid', 'array-contains', user.uid)
                );

                // Execute both queries in parallel
                const [ownedSnapshot, accessibleSnapshot] = await Promise.all([
                    getDocs(ownedClassesQuery),
                    getDocs(accessibleClassesQuery),
                ]);

                // Use a Map to avoid duplicate classes if a user is both owner and has access
                const classesMap = new Map();

                // Add owned classes to the map
                ownedSnapshot.forEach((docSnap) => {
                    classesMap.set(docSnap.id, { id: docSnap.id, ...docSnap.data() });
                });

                // Add accessible classes to the map (duplicates will be ignored)
                accessibleSnapshot.forEach((docSnap) => {
                    if (!classesMap.has(docSnap.id)) {
                        classesMap.set(docSnap.id, { id: docSnap.id, ...docSnap.data() });
                    }
                });

                // Convert the map values to an array
                const classesData = Array.from(classesMap.values());

                setClasses(classesData);
            } catch (error) {
                console.error('Error fetching classes:', error);
                alert('Failed to fetch classes. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchClasses();
    }, []);

    const handleExport = async () => {
        if (!selectedClassId) {
            alert('Please select a class to export.');
            return;
        }
        setLoading(true);
        try {
            // Fetch enrollments for the selected class
            const enrollmentsDoc = await getDoc(doc(db, 'enrollments', selectedClassId));
            if (!enrollmentsDoc.exists()) {
                alert('No enrollments found for this class.');
                setLoading(false);
                return;
            }
            const studentsUids = enrollmentsDoc.data().students || [];
            if (studentsUids.length === 0) {
                alert('No students enrolled in this class.');
                setLoading(false);
                return;
            }

            // Fetch user data for students
            const userPromises = studentsUids.map(uid => getDoc(doc(db, 'users', uid)));
            const userDocs = await Promise.all(userPromises);
            const studentsData = userDocs.map(docSnap => {
                if (docSnap.exists()) {
                    return { uid: docSnap.id, ...docSnap.data() };
                } else {
                    return { uid: docSnap.id };
                }
            });

            // Fetch grades data for the selected class
            const gradesDoc = await getDoc(doc(db, 'grades', selectedClassId));
            const gradesData = gradesDoc.exists() ? gradesDoc.data() : {};

            // Fetch sessions data from Realtime Database
            const rtdb = getDatabase();
            const sessionsRef = ref(rtdb, 'sessions/' + selectedClassId);
            const sessionsSnapshot = await get(sessionsRef);
            const sessionsData = sessionsSnapshot.exists() ? sessionsSnapshot.val() : {};

            // Compute total possible points
            const totalPossiblePoints = Object.values(sessionsData).reduce((sum, session) => {
                const numQuestions = session.total_questions || Object.keys(session.questions || {}).length;
                return sum + numQuestions;
            }, 0);

            // For each student, compute total points and percentage
            const csvData = studentsData.map(student => {
                const studentGrades = gradesData.students?.[student.uid] || {};
                const totalPoints = Object.values(studentGrades).reduce((sum, session) => {
                    const sessionPoints = Object.values(session.points || {}).reduce((s, p) => s + p, 0);
                    return sum + sessionPoints;
                }, 0);

                const percentage = totalPossiblePoints > 0 ? (totalPoints / totalPossiblePoints) * 100 : 0;

                return {
                    universityId: student.universityId || '', // Include universityId
                    firstName: student.firstName || '',
                    lastName: student.lastName || '',
                    Points: totalPoints,
                    Total: totalPossiblePoints,
                    Percentage: percentage.toFixed(2)
                };
            });

            // Generate CSV content
            const headers = ['universityId', 'firstName', 'lastName', 'Points', 'Total', 'Percentage'];
            const csvRows = [];

            // Add headers
            csvRows.push(headers.join(','));

            // Add data rows
            csvData.forEach(row => {
                const values = headers.map(header => {
                    // Escape double quotes by doubling them, and wrap fields containing commas or quotes in double quotes
                    const value = row[header];
                    if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
                        return `"${value.replace(/"/g, '""')}"`;
                    }
                    return value;
                });
                csvRows.push(values.join(','));
            });

            // Create CSV blob
            const csvContent = csvRows.join('\n');
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

            // Create download link
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'grades_export.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(url);

            alert('Export successful!');
        } catch (error) {
            console.error('Error exporting data:', error);
            alert('Failed to export data.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <section
            id="export-section"
            className="justify-content-center align-items-center text-center min-vh-100 mb-4 content-section"
        >
            {loading && <Preloader />}
            <p>Select a class to export grades:</p>
            <select
                value={selectedClassId}
                onChange={e => setSelectedClassId(e.target.value)}
                className="form-select mb-3 w-auto mx-auto"
            >
                <option value="">Select a class</option>
                {classes.map(cls => (
                    <option key={cls.id} value={cls.id}>
                        {cls.class_name}
                    </option>
                ))}
            </select>
            <button onClick={handleExport} className="btn custom-btn">
                Export Data
            </button>
        </section>
    );
};

export default ExportSection;
