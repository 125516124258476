// src/components/professor/SettingsSection.js

import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebase';
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import {
  doc,
  deleteDoc,
  writeBatch,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  getDoc,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore';
import Preloader from '../../components/Preloader';

const SettingsSection = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('account'); // New state for tabs

  // Existing code for account settings
  const handleResetPassword = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (user && user.email) {
        await sendPasswordResetEmail(auth, user.email);
        alert('Password reset email sent. Please check your inbox.');
      } else {
        alert('No authenticated user found.');
      }
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert('Failed to send password reset email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (
      window.confirm(
        'Are you sure you want to delete your account? This action cannot be undone.'
      )
    ) {
      const password = prompt(
        'Please enter your password to confirm account deletion:'
      );
      if (!password) {
        alert('Password is required to delete account.');
        return;
      }

      setLoading(true);
      try {
        const user = auth.currentUser;

        if (user && user.email) {
          // Reauthenticate user
          await signInWithEmailAndPassword(auth, user.email, password);

          // Initialize Firestore
          const batch = writeBatch(db);
          const collectionsToDelete = ['classes', 'enrollments', 'grades'];

          for (const collectionName of collectionsToDelete) {
            const q = query(
              collection(db, collectionName),
              where('professor_uid', '==', user.uid)
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((docSnap) => {
              batch.delete(doc(db, collectionName, docSnap.id));
            });
          }

          await batch.commit();

          // Delete settings
          await deleteDoc(doc(db, 'settings', user.uid));

          // Delete user from Firebase Auth
          await user.delete();

          alert('Account deleted successfully.');
          window.location.href = '/sign-up';
        } else {
          alert('No authenticated user found.');
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        alert(
          'Failed to delete account. Please ensure your password is correct and try again.'
        );
      } finally {
        setLoading(false);
      }
    }
  };

  // New code for class settings
  const [classes, setClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState('');
  const [classDetails, setClassDetails] = useState({
    class_name: '',
    class_invitation: '',
    start_date: '',
    end_date: '',
    statsaccess: false,
    gradeaccess: false,
    sessionaccess: false,
  });
  const [accessEmail, setAccessEmail] = useState('');
  const [accessUsers, setAccessUsers] = useState([]);

  useEffect(() => {
    // Fetch classes when component mounts
    const fetchClasses = async () => {
      setLoading(true);
      try {
        const user = auth.currentUser;
        if (!user) {
          alert('No user is logged in.');
          setLoading(false);
          return;
        }
        const q = query(
          collection(db, 'classes'),
          where('professor_uid', '==', user.uid)
        );
        const querySnapshot = await getDocs(q);
        const classesData = querySnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setClasses(classesData);
      } catch (error) {
        console.error('Error fetching classes:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchClasses();
  }, []);

  const fetchAccessUsers = async (classId) => {
    try {
      const classDoc = await getDoc(doc(db, 'classes', classId));
      if (classDoc.exists()) {
        const data = classDoc.data();
        const accessUids = data.access_uid || [];

        // Fetch user details for each UID
        const accessUsersData = await Promise.all(
          accessUids.map(async (uid) => {
            const userDoc = await getDoc(doc(db, 'users', uid));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              return {
                uid: uid,
                email: userData.email || 'Email not available',
              };
            } else {
              return {
                uid: uid,
                email: 'User not found',
              };
            }
          })
        );

        setAccessUsers(accessUsersData);
      } else {
        alert('Class not found');
        setAccessUsers([]);
      }
    } catch (error) {
      console.error('Error fetching access users:', error);
      setAccessUsers([]);
    }
  };

  const handleClassChange = async (e) => {
    const classId = e.target.value;
    setSelectedClassId(classId);

    if (classId) {
      // Fetch class details
      setLoading(true);
      try {
        const classDoc = await getDoc(doc(db, 'classes', classId));
        if (classDoc.exists()) {
          const data = classDoc.data();
          setClassDetails({
            class_name: data.class_name || '',
            class_invitation: data.class_invitation || '',
            start_date: data.start_date
              ? data.start_date.toDate().toISOString().substr(0, 10)
              : '',
            end_date: data.end_date
              ? data.end_date.toDate().toISOString().substr(0, 10)
              : '',
            statsaccess: data.statsaccess || false,
            gradeaccess: data.gradeaccess || false,
            sessionaccess: data.sessionaccess || false,
          });

          // Fetch access users
          await fetchAccessUsers(classId);
        } else {
          alert('Class not found');
          setClassDetails({
            class_name: '',
            class_invitation: '',
            start_date: '',
            end_date: '',
            statsaccess: false,
            gradeaccess: false,
            sessionaccess: false,
          });
          setAccessUsers([]);
        }
      } catch (error) {
        console.error('Error fetching class details:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setClassDetails({
        class_name: '',
        class_invitation: '',
        start_date: '',
        end_date: '',
        statsaccess: false,
        gradeaccess: false,
        sessionaccess: false,
      });
      setAccessUsers([]);
    }
  };

  const handleClassDetailChange = (e) => {
    const { name, value, type, checked } = e.target;
    setClassDetails((prevDetails) => ({
      ...prevDetails,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleClassSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Check for unique class_invitation code if it has changed
      const user = auth.currentUser;
      if (!user) {
        alert('No user is logged in.');
        setLoading(false);
        return;
      }

      // First, check if class_invitation code is unique if it has changed
      const classDocRef = doc(db, 'classes', selectedClassId);
      const classDoc = await getDoc(classDocRef);
      const originalData = classDoc.data();

      if (classDetails.class_invitation !== originalData.class_invitation) {
        // Check if the new class_invitation code is already used
        const q = query(
          collection(db, 'classes'),
          where('class_invitation', '==', classDetails.class_invitation)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          alert(
            'Class invitation code is already in use. Please choose another one.'
          );
          setLoading(false);
          return;
        }
      }

      // Update class details
      const updatedData = {
        class_name: classDetails.class_name,
        class_invitation: classDetails.class_invitation,
        start_date: classDetails.start_date
          ? new Date(classDetails.start_date)
          : null,
        end_date: classDetails.end_date ? new Date(classDetails.end_date) : null,
        statsaccess: classDetails.statsaccess,
        gradeaccess: classDetails.gradeaccess,
        sessionaccess: classDetails.sessionaccess,
      };
      await updateDoc(classDocRef, updatedData);

      alert('Class details updated successfully.');
    } catch (error) {
      console.error('Error updating class details:', error);
      alert('Failed to update class details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // New functionality: Grant and Revoke Access
  const handleGrantAccess = async () => {
    if (!accessEmail.trim()) {
      alert('Please enter a valid email.');
      return;
    }

    setLoading(true);
    try {
      // Query the users collection to find the user with the given email
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', accessEmail.trim()));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        alert('No user found with the provided email.');
        return;
      }

      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data();

      if (userData.role !== 'professor') {
        alert('Only users with the role of professor can be granted access.');
        return;
      }

      const targetUid = userDoc.id;

      // Check if the user already has access
      if (accessUsers.some((user) => user.uid === targetUid)) {
        alert('This user already has access to the class.');
        return;
      }

      // Add the user's UID to the access_uid array
      const classDocRef = doc(db, 'classes', selectedClassId);
      await updateDoc(classDocRef, {
        access_uid: arrayUnion(targetUid),
      });

      // Update the accessUsers state
      setAccessUsers((prevAccessUsers) => [
        ...prevAccessUsers,
        { uid: targetUid, email: userData.email },
      ]);

      alert('Access granted successfully.');
      setAccessEmail('');
    } catch (error) {
      console.error('Error granting access:', error);
      alert('Failed to grant access. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveAccess = async (uid) => {
    if (
      !window.confirm(
        'Are you sure you want to revoke access for this user?'
      )
    ) {
      return;
    }

    setLoading(true);
    try {
      const classDocRef = doc(db, 'classes', selectedClassId);
      await updateDoc(classDocRef, {
        access_uid: arrayRemove(uid),
      });

      // Update the accessUsers state
      setAccessUsers((prevAccessUsers) =>
        prevAccessUsers.filter((user) => user.uid !== uid)
      );

      alert('Access revoked successfully.');
    } catch (error) {
      console.error('Error revoking access:', error);
      alert('Failed to revoke access. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section
      id="settings-section"
      className="justify-content-center align-items-center text-center min-vh-100 mb-4 content-section"
    >
      {loading && <Preloader />}

      {/* Bootstrap Nav Tabs */}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 'account' ? 'active' : ''}`}
            onClick={() => setActiveTab('account')}
            role="tab"
            href="#!"
          >
            Account Settings
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 'classes' ? 'active' : ''}`}
            onClick={() => setActiveTab('classes')}
            role="tab"
            href="#!"
          >
            Class Settings
          </a>
        </li>
      </ul>

      <div className="tab-content mt-3">
        {activeTab === 'account' && (
          <div className="tab-pane fade show active" role="tabpanel">
            {/* Account Security */}
            <div className="settings-group">
              <h3>Account Security</h3>
              <button
                onClick={handleResetPassword}
                className="btn custom-btn"
              >
                Reset Password
              </button>
            </div>

            {/* Account Management */}
            <div className="settings-group">
              <h3>Account Management</h3>
              <button
                onClick={handleDeleteAccount}
                className="btn custom-btn delete-btn"
              >
                Delete Account
              </button>
            </div>
          </div>
        )}

        {activeTab === 'classes' && (
          <div className="tab-pane fade show active" role="tabpanel">
            <p>Select a class to edit its details:</p>
            <p><strong>Note:</strong> If you are an admin, your classes will appear in the dropdown menu below.</p>
            <div className="d-flex justify-content-center mb-4">
              <select
                value={selectedClassId}
                onChange={handleClassChange}
                className="form-select w-auto"
              >
                <option value="">Select a class</option>
                {classes.map((cls) => (
                  <option key={cls.id} value={cls.id}>
                    {cls.class_name}
                  </option>
                ))}
              </select>
            </div>

            {selectedClassId && (
              <>
                <form onSubmit={handleClassSave} className="class-details-form">
                  <div className="row mb-3 align-items-center">
                    <label
                      htmlFor="class_name"
                      className="col-sm-3 col-form-label text-end"
                    >
                      Class Name:
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="class_name"
                        name="class_name"
                        value={classDetails.class_name}
                        onChange={handleClassDetailChange}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3 align-items-center">
                    <label
                      htmlFor="class_invitation"
                      className="col-sm-3 col-form-label text-end"
                    >
                      Class Invitation Code:
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="class_invitation"
                        name="class_invitation"
                        value={classDetails.class_invitation}
                        onChange={handleClassDetailChange}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3 align-items-center">
                    <label
                      htmlFor="start_date"
                      className="col-sm-3 col-form-label text-end"
                    >
                      Start Date:
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="date"
                        id="start_date"
                        name="start_date"
                        value={classDetails.start_date}
                        onChange={handleClassDetailChange}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="row mb-3 align-items-center">
                    <label
                      htmlFor="end_date"
                      className="col-sm-3 col-form-label text-end"
                    >
                      End Date:
                    </label>
                    <div className="col-sm-6">
                      <input
                        type="date"
                        id="end_date"
                        name="end_date"
                        value={classDetails.end_date}
                        onChange={handleClassDetailChange}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-sm-3 text-end">
                      <label className="col-form-label">Access Controls:</label>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          name="statsaccess"
                          checked={classDetails.statsaccess}
                          onChange={handleClassDetailChange}
                          className="form-check-input"
                          id="statsaccess"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="statsaccess"
                        >
                          Stats Access
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          name="gradeaccess"
                          checked={classDetails.gradeaccess}
                          onChange={handleClassDetailChange}
                          className="form-check-input"
                          id="gradeaccess"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="gradeaccess"
                        >
                          Grade Access
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          name="sessionaccess"
                          checked={classDetails.sessionaccess}
                          onChange={handleClassDetailChange}
                          className="form-check-input"
                          id="sessionaccess"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="sessionaccess"
                        >
                          Session Access
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                      <button type="submit" className="btn custom-btn">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>

                {/* Access Management Section */}
                <div className="access-management mt-5">
                  <h4>Manage Access</h4>
                  <div className="d-flex justify-content-center mb-3">
                    <input
                      type="email"
                      placeholder="Enter professor's email"
                      value={accessEmail}
                      onChange={(e) => setAccessEmail(e.target.value)}
                      className="form-control w-auto me-2"
                    />
                    <button
                      onClick={handleGrantAccess}
                      className="btn custom-btn"
                    >
                      Grant Access
                    </button>
                  </div>

                  <h5>Current Access Users:</h5>
                  {accessUsers.length === 0 ? (
                    <p>No users have been granted access.</p>
                  ) : (
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {accessUsers.map((user) => (
                          <tr key={user.uid}>
                            <td>{user.email}</td>
                            <td>
                              <button
                                onClick={() => handleRemoveAccess(user.uid)}
                                className="btn btn-danger btn-sm"
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default SettingsSection;
