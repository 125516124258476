import React from 'react';

function QuestionDisplay({
  user,
  handleSignOut,
  currentQuestion,
  questionData,
  localSelectedAnswer,
  setLocalSelectedAnswer,
  submitAnswer,
  isAnswerCorrect,
  pointsEarned,
}) {
  const pollActive = questionData.pool === 'on';
  const questionType = questionData.question_type;
  const correctAnswer = questionData.correct_answer;

  // Add refresh state to force re-render
  const [refresh, setRefresh] = React.useState(false);

  let answerInputHtml = null;

  if (questionType === 'multiple_choice') {
    answerInputHtml = (
      <div className="multiple-choice">
        <p>Choose your answer:</p>
        {['A', 'B', 'C', 'D', 'E'].map((option) => (
          <button
            key={option}
            className={`answer-btn ${
              localSelectedAnswer && localSelectedAnswer.includes(option) ? 'selected' : ''
            }`}
            disabled={!pollActive}
            onClick={() => {
              setLocalSelectedAnswer([option]);
              submitAnswer([option]);
              // Force re-render
              setRefresh((prev) => !prev);
            }}
            style={{ userSelect: 'none' }}
          >
            {option}
          </button>
        ))}
      </div>
    );
  } else if (questionType === 'multiple_multiple_choice') {
    answerInputHtml = (
      <div className="multiple-multiple-choice">
        <p>Multiple Answers</p>
        <p>Choose your answers:</p>
        {['A', 'B', 'C', 'D', 'E'].map((option) => {
          const isSelected = localSelectedAnswer && localSelectedAnswer.includes(option);
          return (
            <button
              key={`${option}-${isSelected}`}
              className={`answer-btn ${isSelected ? 'selected' : ''}`}
              disabled={!pollActive}
              onClick={() => {
                let updatedAnswers = localSelectedAnswer ? [...localSelectedAnswer] : [];
                if (isSelected) {
                  // Deselect the option
                  updatedAnswers = updatedAnswers.filter((opt) => opt !== option);
                } else {
                  // Select the option
                  updatedAnswers.push(option);
                }
                setLocalSelectedAnswer(updatedAnswers);
                submitAnswer(updatedAnswers);
                // Force re-render
                setRefresh((prev) => !prev);
              }}
              style={{ userSelect: 'none' }}
            >
              {option}
            </button>
          );
        })}
      </div>
    );
  } else if (questionType === 'yes_no') {
    answerInputHtml = (
      <div className="yes-no">
        <p>Choose your answer:</p>
        {['Yes', 'No'].map((option) => (
          <button
            key={option}
            className={`answer-btn ${localSelectedAnswer === option ? 'selected' : ''}`}
            disabled={!pollActive}
            onClick={() => {
              setLocalSelectedAnswer(option);
              submitAnswer(option);
              // Force re-render
              setRefresh((prev) => !prev);
            }}
            style={{ userSelect: 'none' }}
          >
            {option}
          </button>
        ))}
      </div>
    );
  } else if (questionType === 'short_input') {
    // Determine if the input exceeds 20 characters
    const isInputTooLong = localSelectedAnswer && localSelectedAnswer.length > 20;

    answerInputHtml = (
      <div className="short-input">
        <p>Type your answer (Max 20 characters):</p>
        <input
          type="text"
          id="short-answer"
          value={localSelectedAnswer || ''}
          placeholder="Enter your answer"
          onChange={(e) => setLocalSelectedAnswer(e.target.value)}
          disabled={!pollActive}
          maxLength={20}
        />
        <button
          className={`answer-btn ${!isInputTooLong && localSelectedAnswer ? 'selected' : ''}`}
          onClick={() => submitAnswer(localSelectedAnswer)}
          disabled={!pollActive || isInputTooLong || !localSelectedAnswer}
          style={{ userSelect: 'none' }}
        >
          Submit
        </button>
        {isInputTooLong && (
          <p className="error-message" style={{ color: 'red', marginTop: '5px' }}>
            Answer cannot exceed 20 characters.
          </p>
        )}
      </div>
    );
  }

  // Function to format and display answers
  const formatAnswer = (answer) => {
    if (Array.isArray(answer)) {
      return answer.sort().join(', ');
    }
    return answer || 'Not answered';
  };

  return (
    <section className="main-content section-padding" key={currentQuestion.questionNumber}>
      <div className="session-details">
        <h3>Question {currentQuestion.questionNumber}</h3>

        {questionData.question_photo === 'InClass' ? (
          <h2 className="inclass-message">This question has been posted in class.</h2>
        ) : (
          <img
            src={questionData.question_photo}
            alt="Question"
            className="responsive-image"
          />
        )}

        {answerInputHtml}

        {correctAnswer !== undefined && (
          <div className="correct-answer" style={{ marginTop: '20px' }}>
            <h4 style={{ color: 'green' }}>Correct Answer: {formatAnswer(correctAnswer)}</h4>
            <h4
              style={{
                color:
                  isAnswerCorrect === null
                    ? 'black'
                    : isAnswerCorrect
                    ? 'green'
                    : 'red',
              }}
            >
              Your Answer: {formatAnswer(localSelectedAnswer)}
            </h4>
            <p className="poll-closed-msg">Points Earned: {pointsEarned}</p>
          </div>
        )}

        {!pollActive && (
          <p className="poll-closed-msg">
            Poll is closed. You cannot change your answer.
          </p>
        )}
      </div>
    </section>
  );
}

export default QuestionDisplay;
