import React from 'react';

function Footer() {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-10 me-auto mb-4">
            <h4 className="text-white mb-3"><a href="/">Cours</a>Engage</h4>
            <p className="copyright-text text-muted mt-lg-5 mb-4 mb-lg-0">
              Copyright © 2024 <strong>CoursEngage</strong>
            </p>
            <br />
            <p className="copyright-text">
              Designed and Made by <a href="https://debugger-developers.web.app" target="_blank" rel="noreferrer">Debugger Developers</a>
            </p>
          </div>

          <div className="col-lg-5 col-8">
            <h5 className="text-white mb-3">Sitemap</h5>
            <ul className="footer-menu d-flex flex-wrap">
              <li className="footer-menu-item"><a href="/about" className="footer-menu-link">About Us</a></li>
              <li className="footer-menu-item"><a href="/product-detail" className="footer-menu-link">Product Detail</a></li>
              <li className="footer-menu-item"><a href="/privacypolicy" className="footer-menu-link">Privacy Policy</a></li>
              {/* <li className="footer-menu-item"><a href="/faq" className="footer-menu-link">FAQs</a></li> */}
              <li className="footer-menu-item"><a href="/contact" className="footer-menu-link">Contact</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
