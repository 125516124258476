import React from 'react';

function ClassList({ classes, handleClassClick, handleAddClassClick }) {
  return (
    <section className="main-content">
        <div id="class-cards" className="container">
        {classes.map((classItem) => (
            <div
            key={classItem.classId}
            className={`class-card ${classItem.hasActiveSession ? 'active-session' : ''}`}
            onClick={() => handleClassClick(classItem.classId)}
            >
            <h3>{classItem.className}</h3>
            {classItem.hasActiveSession ? (
                <p className="session-active">Session is active</p>
            ) : (
                <p className="session-inactive">Session is not active</p>
            )}
            {/* <p>Invitation Code: {classItem.classInvitation}</p> */}
            </div>
        ))}
        </div>
      <button
        id="add-class-btn"
        className="btn custom-btn mt-4"
        onClick={handleAddClassClick}
      >
        Add Class
      </button>
    </section>
  );
}

export default ClassList;
