import React from 'react';
import '../../css/professor/ProfessorDashboard.css';

function Sidebar() {
  const handleNavClick = (e, targetId) => {
    e.preventDefault();
    // Remove active class from all links
    document.querySelectorAll('.sidebar-nav a').forEach(link => link.classList.remove('active'));
    // Add active class to the clicked link
    e.currentTarget.classList.add('active');
    // Hide all content sections
    document.querySelectorAll('.content-section').forEach(section => section.classList.remove('active-section'));
    // Show the targeted content section
    document.getElementById(targetId).classList.add('active-section');
  };

  return (
    <div className="sidebar">
      <ul className="sidebar-nav">
        <li>
          <a href="#" className="nav-link active" onClick={(e) => handleNavClick(e, 'class-section')}>
            <i className="bi bi-journal-bookmark"></i>
            <span>Class</span>
          </a>
        </li>
        <li>
          <a href="#" className="nav-link" onClick={(e) => handleNavClick(e, 'export-section')}>
            <i className="bi bi-file-earmark-arrow-down"></i>
            <span>Export</span>
          </a>
        </li>
        {/* <li>
          <a href="#" className="nav-link" onClick={(e) => handleNavClick(e, 'usage-section')}>
            <i className="bi bi-bar-chart"></i>
            <span>Usage</span>
          </a>
        </li> */}
        <li>
          <a href="#" className="nav-link" onClick={(e) => handleNavClick(e, 'install-section')}>
            <i className="bi bi-cloud-arrow-down"></i>
            <span>Install</span>
          </a>
        </li>
        <li>
          <a href="#" className="nav-link" onClick={(e) => handleNavClick(e, 'integration-section')}>
            <i className="bi bi-cloud-arrow-down"></i>
            <span>Integration</span>
          </a>
        </li>
        <li>
          <a href="#" className="nav-link" onClick={(e) => handleNavClick(e, 'settings-section')}>
            <i className="bi bi-gear"></i>
            <span>Settings</span>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
