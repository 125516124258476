import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import StudentGradesModal from './StudentGradesModal';

const StudentList = ({
  students,
  removeStudent,
  selectedStudent,
  setSelectedStudent,
  sessions,
  classId,
}) => {
  const [grades, setGrades] = useState({});
  const [studentSearchTerm, setStudentSearchTerm] = useState('');

  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const gradesDocRef = doc(db, 'grades', classId);
        const gradesDocSnap = await getDoc(gradesDocRef);
        if (gradesDocSnap.exists()) {
          setGrades(gradesDocSnap.data());
        } else {
          console.log('No grades data found');
        }
      } catch (error) {
        console.error('Error fetching grades:', error);
      }
    };

    fetchGrades();
  }, [classId]);

  const handleStudentSearch = (e) => {
    setStudentSearchTerm(e.target.value);
  };

  const filteredStudents = students.filter((student) =>
    `${student.firstName} ${student.lastName}`
      .toLowerCase()
      .includes(studentSearchTerm.toLowerCase())
  );

  const viewStudentGrades = (student) => {
    setSelectedStudent(student);
  };

  const closeStudentGrades = () => {
    setSelectedStudent(null);
  };

  // Add this useEffect hook
  useEffect(() => {
    if (selectedStudent) {
      window.scrollTo(0, 0);
    }
  }, [selectedStudent]);

  return (
    <>
      {/* Student Search */}
      <h3>Students</h3>
      <input
        type="text"
        placeholder="Search Students..."
        value={studentSearchTerm}
        onChange={handleStudentSearch}
        className="form-control-sm me-2"
      />

      
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Total Points</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student) => {
            const studentGrades = grades.students?.[student.uid] || {};
            const totalPoints = Object.values(studentGrades).reduce(
              (sum, session) =>
                sum + Object.values(session.points || {}).reduce((s, p) => s + p, 0),
              0
            );
            return (
              <tr key={student.uid}>
                <td>
                  {student.firstName} {student.lastName}
                </td>
                <td>{student.email}</td>
                <td>{totalPoints.toFixed(2)}</td>
                <td>
                  <button
                    onClick={() => viewStudentGrades(student)}
                    className="btn btn-info"
                  >
                    View Grades
                  </button>{' '}
                  <button
                    onClick={() => removeStudent(student.uid)}
                    className="btn btn-danger"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Student Grades Modal */}
      {selectedStudent && (
        <StudentGradesModal
          className="modal-lg"
          student={selectedStudent}
          gradesData={grades}
          sessionsData={sessions}
          closeModal={closeStudentGrades}
          classId={classId}
        />
      )}
    </>
  );
};

export default StudentList;
