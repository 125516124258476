import React from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import '../../css/professor/ProfessorDashboard.css';

function NavBar({ userEmail, onSignOut }) {
  const location = useLocation(); // Get the current location

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand" href="/professor">
          <strong><span>Cours</span>Engage</strong>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <a className={`nav-link ${location.pathname === '/professor' ? 'active' : ''}`} href="/professor">Home</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${location.pathname === '/professor-support' ? 'active' : ''}`} href="/professor-support">Support</a>
            </li>
            {/* <li className="nav-item">
              <a className={`nav-link ${location.pathname === '/professor-documentation' ? 'active' : ''}`} href="/professor-documentation">Documentation</a>
            </li> */}
          </ul>
          <div className="d-none d-lg-block d-flex align-items-center">
            <span className="me-3 user-email" style={{ color: 'var(--p-color)' }}>{userEmail}</span>
            <button onClick={onSignOut} className="btn custom-btn">Sign Out</button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
