import React, { useState, useEffect } from 'react';
import Preloader from '../Preloader';
import { getDatabase, ref, get } from 'firebase/database';

const StudentSessionList = ({ sessions, classId, user, grades }) => {
  const [sessionSearchTerm, setSessionSearchTerm] = useState('');
  const [expandedSessions, setExpandedSessions] = useState({});
  const [loading, setLoading] = useState(false);
  const [answers, setAnswers] = useState({});

  const handleSessionSearch = (e) => {
    setSessionSearchTerm(e.target.value);
  };

  const toggleSessionExpand = (sessionId) => {
    setExpandedSessions({
      ...expandedSessions,
      [sessionId]: !expandedSessions[sessionId],
    });
  };

  // Fetch student's answers from Realtime Database
  useEffect(() => {
    const fetchAnswers = async () => {
      setLoading(true);
      const db = getDatabase();
      const studentUid = user.uid; // Assuming user.uid is available
      const answersRef = ref(db, `answers/${classId}`);

      try {
        const snapshot = await get(answersRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const studentAnswers = {};

          Object.entries(data).forEach(([sessionId, sessionData]) => {
            Object.entries(sessionData).forEach(([questionNumber, questionData]) => {
              if (questionData[studentUid]) {
                if (!studentAnswers[sessionId]) {
                  studentAnswers[sessionId] = {};
                }
                studentAnswers[sessionId][questionNumber] = questionData[studentUid];
              }
            });
          });

          setAnswers(studentAnswers);
        } else {
          setAnswers({});
        }
      } catch (error) {
        console.error('Error fetching answers:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAnswers();
  }, [classId, user.uid]);

  // Filter sessions based on search term
  const filteredSessions = sessions.filter((session) => {
    const sessionDate = new Date(session.start_date_time);
    return sessionDate.toLocaleDateString().includes(sessionSearchTerm);
  });

  if (loading) {
    return <Preloader />;
  }

  // Function to get display name for question type
  const getQuestionTypeDisplayName = (type) => {
    switch (type) {
      case 'multiple_choice':
        return 'Multiple Choice';
      case 'multiple_multiple_choice':
        return 'Multiple Answers';
      case 'yes_no':
        return 'Yes/No';
      case 'short_input':
        return 'Input';
      default:
        return type;
    }
  };

  return (
    <>
      {/* Session Search */}
      <h3>Sessions</h3>
      <input
        type="text"
        placeholder="Search Sessions..."
        value={sessionSearchTerm}
        onChange={handleSessionSearch}
        className="form-control-sm me-2"
      />

      <div className="sessions-list">
        {filteredSessions.map((session) => {
          // Compute max questionNumber
          const questionNumbers = Object.keys(session.questions || {}).map(Number);
          const maxQuestionNumber = questionNumbers.length > 0 ? Math.max(...questionNumbers) : 0;

          return (
            <div key={session.id} className="session-item">
              <div
                className="session-header d-flex justify-content-between align-items-center"
                onClick={() => toggleSessionExpand(session.id)}
              >
                <h4 className="mb-0">
                  Session on {new Date(session.start_date_time).toLocaleString()}
                </h4>
                <span>{expandedSessions[session.id] ? '▲' : '▼'}</span>
              </div>

              {expandedSessions[session.id] && (
                <div className="session-details">
                  <p className="mb-4">
                    <strong>Total Questions:</strong> {maxQuestionNumber}
                  </p>

                  {session.questions &&
                    Object.entries(session.questions).map(
                      ([questionNumber, question]) => (
                        <div
                          key={questionNumber}
                          className="question-item mb-4 p-3 border rounded"
                        >
                          <h5 className="mb-3">Question {questionNumber}</h5>

                          {/* Conditional Rendering for Question Photo */}
                          {question.question_photo ? (
                            question.question_photo === 'InClass' ? (
                              <div className="alert custom-alert" role="alert">
                                This question was presented in the class.
                              </div>
                            ) : (
                              <img
                                src={question.question_photo}
                                alt={`Question ${questionNumber}`}
                                className="img-fluid mb-3"
                              />
                            )
                          ) : null}

                          <p className="mb-2">
                            <strong>Type:</strong> {getQuestionTypeDisplayName(question.question_type)}
                          </p>

                          {/* Possible Answers */}
                          {question.possible_answers && (
                            <div className="mb-2">
                              <strong>Possible Answers:</strong>
                              <ul className="list-group list-group-flush">
                                {question.possible_answers.map((answer, index) => (
                                  <li key={index} className="list-group-item">
                                    {answer}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}

                          <p className="mb-2">
                            <strong>Correct Answer:</strong>{' '}
                            {question.correct_answer || 'Not set'}
                          </p>

                          <p className="mb-2">
                            <strong>Your Answer:</strong>{' '}
                            {answers[session.id]?.[questionNumber]?.answer ||
                              'Not Answered'}
                          </p>

                          <p className="mb-3">
                            <strong>Points Earned:</strong>{' '}
                            {grades[session.id]?.points?.[questionNumber] ?? '0'}
                          </p>
                        </div>
                      )
                    )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default StudentSessionList;
